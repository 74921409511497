<template>
  <div>
    <div class="top">
      <div class="main">
        <!--        <img class="logo" src="/static/image/ivory_black.png" alt="">-->
        <div class="nav_bar">
          <ul>
            <li><a href="">首页 </a></li>
            <li class="l1">
              <a href="#">产品 &nbsp;<i class="layui-icon layui-icon-down"></i></a>
              <div class="hid">
                <ul>
                  <li><a href="#">电脑端</a></li>
                  <li><a href="javascript:" @click="openQrCode('ios')" class="IosDownload">苹果APP</a></li>
                  <li><a href="javascript:" @click="openQrCode('android')" class="androidDownload">安卓APP</a></li>
                </ul>
              </div>
            </li>
            <li class="l1">
              <a href="#">培训 &nbsp;<i class="layui-icon layui-icon-down"></i></a>
              <div class="hid">
                <ul>
                  <li><a href="#">配货培训</a></li>
                  <li><a href="#">主理人培训</a></li>
                  <li><a href="#">礼服师培训</a></li>
                </ul>
              </div>
            </li>
            <li><a href="#contactMe">联系我们</a></li>
            <li><a href="#">关于我们</a></li>
          </ul>
        </div>
        <div class="login">
          <a @click="toLogin()" class="loginBtn">登录</a>
          <a @click="toRegister()" class="regBtn">注册</a>
        </div>
      </div>
    </div>
    <div class="product">

      <ul>
        <li>
          <p><img class="android" src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/android.png" alt="Android"/></p>
        </li>
        <li>
          <p><img class="pc" src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/pc.png" alt="PC"/></p>
        </li>
        <li>
          <p><img class="ios" src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/ios.png" alt="IOS"/></p>
        </li>
      </ul>

    </div>
    <div class="numberPoster">
      <div style="margin: 3% 0;color: #FFFFFF">
        <h2>助力门店提升效率</h2>
      </div>
      <div>
        <ul>
          <li>
            <p><img src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster1.png" alt="客服效率提升86%"/></p>
            <h3>客服效率提升</h3>
          </li>
          <li>
            <p><img  src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster2.png" alt="客户满意度高达96%"/></p>
            <h3>客户满意度高达</h3>
          </li>
          <li>
            <p><img  src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster3.png" alt="每年成本节约10W"/></p>
            <h3>每年成本节约</h3>
          </li>
          <li>
            <p><img  src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster4.png" alt="流程效率提升约20%"/></p>
            <h3>流程效率提升约</h3>
          </li>
        </ul>
      </div>

    </div>
    <div class="labelPoster">
      <div style="margin: 5% 0;color: #000000">
        <h2>打造行业领先价值</h2>
      </div>
      <div>
        <ul>
          <li>
            <p><img src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster1.png" alt="整合客服机器人，提供客服数据报表，智能客服监控、客服绩效，帮助用户完成客服管理。"></p>
            <h3>商业价值</h3>
            <p>360°产品、市场、用户信息沉淀，助力店铺商业决策，帮助抵御风险，赢得先机。</p>
          </li>
          <li>
            <p><img src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster2.png" alt="网易七鱼智能机器人，智能绩效节省企业客户管理成本"></p>
            <h3>客服效率</h3>
            <p>10分钟轻松打通多渠道服务，一统用户消息；团队分工更专业，客服效率提升90%。</p>
          </li>
          <li>
            <p><img src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster3.png" alt="网易七鱼在线客户系统帮助企业提升客服效率"></p>
            <h3>用户体验</h3>
            <p>流程管理优化、服务数据分析帮助从源头消灭问题，满意度提升25%。</p>
          </li>
          <li>
            <p><img src="https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster4.png" alt="网易七鱼客服人机协作，提升客服服务满意度"></p>
            <h3>管理成本</h3>
            <p>预约订单问题及时发现，系统实时统计数据，平均管理成本节约60%以上。</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <ul>
        <li class="title">产品</li>
        <li>
          <a href="#" title="ISASA 电脑端应用">电脑端</a>
        </li>
        <li>
          <a href="javascript:" class="androidDownload" title="ISASA 安卓APP">安卓APP</a>
        </li>
        <li>
          <a href="javascript:" class="IosDownload" title="ISASA 苹果APP">苹果APP</a>
        </li>
      </ul>
      <ul>
        <li class="title">帮助</li>
        <li>
          <a href="javascript:" target="_blank">使用指南</a>
        </li>
        <li>
          <a href="privacy-statement.html" target="_blank">隐私条款</a>
        </li>
        <li>
          <a href="service-agreement.html" target="_blank">服务条款</a>
        </li>
      </ul>
      <ul>
        <li class="title" id="contactMe">联系我们</li>
        <li>
          <a href="javascript:">微信: Ivorydoris</a>
        </li>
        <li>
          <a href="https://weibo.com/u/6624027241" target="_blank">新浪微博</a>
        </li>
      </ul>
      <div style="cursor: pointer" @click="toAboutUs()">
        沪ICP备2021014967号-1
      </div>
    </div>
  </div>

</template>

<script>
import android from "@/assets/index/android.png"
import pc from "@/assets/index/pc.png"
import ios from "@/assets/index/ios.png"
import numberPoster1 from "@/assets/index/numberPoster1.png"
import numberPoster2 from "@/assets/index/numberPoster2.png"
import numberPoster3 from "@/assets/index/numberPoster3.png"
import numberPoster4 from "@/assets/index/numberPoster4.png"
import labelPoster1 from "@/assets/index/labelPoster1.png"
import labelPoster2 from "@/assets/index/labelPoster2.png"
import labelPoster3 from "@/assets/index/labelPoster3.png"
import labelPoster4 from "@/assets/index/labelPoster4.png"

export default {
  name: "index",
  data() {
    return {
      android: android,
      pc: pc,
      ios: ios,
      numberPoster1: numberPoster1,
      numberPoster2: numberPoster2,
      numberPoster3: numberPoster3,
      numberPoster4: numberPoster4,
      labelPoster1: labelPoster1,
      labelPoster2: labelPoster2,
      labelPoster3: labelPoster3,
      labelPoster4: labelPoster4,
    }
  },
  methods: {
    toLogin: function () {
      this.$router.push('/login')
    },
    toRegister: function () {
      this.$router.push('/register')
    },
    openQrCode(type){
      let img=type==="ios"?require('../../assets/img/download/IOSDownload.png'):require('../../assets/img/download/APKDownload.png')
      this.$viewerApi({
        images: [img],
      })
    },
    toAboutUs(){
      window.open("https://beian.miit.gov.cn")
    }
  }
}
</script>

<style scoped>
/*修改浏览器滚动条开始*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.top {
  width: 100%;
  background: #FFFFFF;
  height: 60px;
  position: fixed;
}

.main {
  width: 1210px;
  margin: 0 auto;
}

.logo {
  width: 60px;
  height: 60px;
  float: left;
}

.nav_bar {
  width: 600px;
  float: left;
  height: 60px;
  margin-left: 20%;
}

.nav_bar ul li {
  list-style-type: none;
  float: left;
  line-height: 60px;
  text-align: center;
  width: 100px;
}

.nav_bar ul li a {
  font-size: 15px;
  font-family: "Microsoft yahei", serif;
  color: #000000;
  text-decoration: none;
  display: block;
}

.nav_bar > ul > li > a:hover {
  color: rgb(61, 159, 255);
}

.nav_bar ul li.l1:hover .hid {
  display: block;
}

.hid {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  position: absolute;
  left: 0;
  display: none;
  margin: 0 auto;
  border-top: 1px #cfc8c8 solid
}

.hid ul {
  width: 1210px;
  margin: 0 auto;
}

.hid ul li {
  margin: 0 2%;
}

.login {
  float: right;
  width: 150px;
  height: 30px;
  margin-top: 15px;
}

.login a {
  cursor:pointer;
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  float: left;
  color: #000000;
  border: 1px solid #666;
  border-radius: 7px;
  text-decoration: none;
  margin: 0 2%;
  font-size: 14px;
}

.login a.loginBtn:hover {
  border: 1px solid rgb(61, 159, 255);
  /*font-size: 16px;*/
  /*background: #9daff5;*/
  color: rgb(61, 159, 255);
}

.login a.regBtn:hover {
  border: 1px solid rgb(61, 159, 255);
  /*font-size: 16px;*/
  /*background: #9daff5;*/
  /*color: #9daff5;*/
  color: rgb(61, 159, 255);
}

.product {
  width: 100%;
  height: 650px;
  padding-top: 7%;
  padding-bottom: 3%;
  text-align: center;
}

.product ul {
  display: flex;
  justify-content: space-around;
}

.android {
  width: 250px;
  height: 450px;
}

.pc {
  width: 850px;
  height: 600px;
}

.ios {
  width: 250px;
  height: 450px;
}

.numberPoster {
  width: 100%;
  height: 500px;
  background: #4091ff;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
}

.numberPoster ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.numberPoster ul li {
  list-style: none;
  margin: 0 2%;
  color: #FFFFFF;
}

.numberPoster ul li h3 {
  margin: 7% 0;
}

.labelPoster {
  width: 75%;
  height: 550px;
  background: #FFFFFF;
  margin: 1% auto;
  text-align: center;
}

.labelPoster ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.labelPoster ul li {
  list-style: none;
  margin: 0 2%;
  color: #000000;
}

.labelPoster ul li h3 {
  margin: 25px 0;
}

.bottom {
  width: 100%;
  background: #30343e;
  text-align: center;
}

.bottom ul {
  margin: 3% 10% 0 10%;
  display: inline-block;
  height: 350px;
  overflow: hidden;

}

.bottom ul .title {
  margin-bottom: 10%;
  color: #FFFFFF;
  font-size: 20px;

}

.bottom ul li {
  margin: 20px 0;
}

.bottom ul li a {
  color: rgb(166, 158, 146);

}

.bottom div {
  height: 50px;
  color: rgb(166, 158, 146);
}
</style>