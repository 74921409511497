var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"nav_bar"},[_c('ul',[_vm._m(0),_c('li',{staticClass:"l1"},[_vm._m(1),_c('div',{staticClass:"hid"},[_c('ul',[_vm._m(2),_c('li',[_c('a',{staticClass:"IosDownload",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.openQrCode('ios')}}},[_vm._v("苹果APP")])]),_c('li',[_c('a',{staticClass:"androidDownload",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.openQrCode('android')}}},[_vm._v("安卓APP")])])])])]),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"login"},[_c('a',{staticClass:"loginBtn",on:{"click":function($event){return _vm.toLogin()}}},[_vm._v("登录")]),_c('a',{staticClass:"regBtn",on:{"click":function($event){return _vm.toRegister()}}},[_vm._v("注册")])])])]),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"bottom"},[_vm._m(9),_vm._m(10),_vm._m(11),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toAboutUs()}}},[_vm._v(" 沪ICP备2021014967号-1 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":""}},[_vm._v("首页 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_vm._v("产品  "),_c('i',{staticClass:"layui-icon layui-icon-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("电脑端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l1"},[_c('a',{attrs:{"href":"#"}},[_vm._v("培训  "),_c('i',{staticClass:"layui-icon layui-icon-down"})]),_c('div',{staticClass:"hid"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("配货培训")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("主理人培训")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("礼服师培训")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#contactMe"}},[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('ul',[_c('li',[_c('p',[_c('img',{staticClass:"android",attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/android.png","alt":"Android"}})])]),_c('li',[_c('p',[_c('img',{staticClass:"pc",attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/pc.png","alt":"PC"}})])]),_c('li',[_c('p',[_c('img',{staticClass:"ios",attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/ios.png","alt":"IOS"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"numberPoster"},[_c('div',{staticStyle:{"margin":"3% 0","color":"#FFFFFF"}},[_c('h2',[_vm._v("助力门店提升效率")])]),_c('div',[_c('ul',[_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster1.png","alt":"客服效率提升86%"}})]),_c('h3',[_vm._v("客服效率提升")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster2.png","alt":"客户满意度高达96%"}})]),_c('h3',[_vm._v("客户满意度高达")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster3.png","alt":"每年成本节约10W"}})]),_c('h3',[_vm._v("每年成本节约")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/numberPoster4.png","alt":"流程效率提升约20%"}})]),_c('h3',[_vm._v("流程效率提升约")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"labelPoster"},[_c('div',{staticStyle:{"margin":"5% 0","color":"#000000"}},[_c('h2',[_vm._v("打造行业领先价值")])]),_c('div',[_c('ul',[_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster1.png","alt":"整合客服机器人，提供客服数据报表，智能客服监控、客服绩效，帮助用户完成客服管理。"}})]),_c('h3',[_vm._v("商业价值")]),_c('p',[_vm._v("360°产品、市场、用户信息沉淀，助力店铺商业决策，帮助抵御风险，赢得先机。")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster2.png","alt":"网易七鱼智能机器人，智能绩效节省企业客户管理成本"}})]),_c('h3',[_vm._v("客服效率")]),_c('p',[_vm._v("10分钟轻松打通多渠道服务，一统用户消息；团队分工更专业，客服效率提升90%。")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster3.png","alt":"网易七鱼在线客户系统帮助企业提升客服效率"}})]),_c('h3',[_vm._v("用户体验")]),_c('p',[_vm._v("流程管理优化、服务数据分析帮助从源头消灭问题，满意度提升25%。")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"https://isasaerp-img-1304365928.cos.ap-shanghai.myqcloud.com/labelPoster4.png","alt":"网易七鱼客服人机协作，提升客服服务满意度"}})]),_c('h3',[_vm._v("管理成本")]),_c('p',[_vm._v("预约订单问题及时发现，系统实时统计数据，平均管理成本节约60%以上。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"title"},[_vm._v("产品")]),_c('li',[_c('a',{attrs:{"href":"#","title":"ISASA 电脑端应用"}},[_vm._v("电脑端")])]),_c('li',[_c('a',{staticClass:"androidDownload",attrs:{"href":"javascript:","title":"ISASA 安卓APP"}},[_vm._v("安卓APP")])]),_c('li',[_c('a',{staticClass:"IosDownload",attrs:{"href":"javascript:","title":"ISASA 苹果APP"}},[_vm._v("苹果APP")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"title"},[_vm._v("帮助")]),_c('li',[_c('a',{attrs:{"href":"javascript:","target":"_blank"}},[_vm._v("使用指南")])]),_c('li',[_c('a',{attrs:{"href":"privacy-statement.html","target":"_blank"}},[_vm._v("隐私条款")])]),_c('li',[_c('a',{attrs:{"href":"service-agreement.html","target":"_blank"}},[_vm._v("服务条款")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"title",attrs:{"id":"contactMe"}},[_vm._v("联系我们")]),_c('li',[_c('a',{attrs:{"href":"javascript:"}},[_vm._v("微信: Ivorydoris")])]),_c('li',[_c('a',{attrs:{"href":"https://weibo.com/u/6624027241","target":"_blank"}},[_vm._v("新浪微博")])])])
}]

export { render, staticRenderFns }